export default class TripQuickOpenView {
  constructor() {
    this.setRemoteTypeahead()
  }

  setRemoteTypeahead() {
    $('#trip_lookup').remoteTypeahead({
      idSelector: "#trip_id",
      url:        "/trips.json",
      predicate:  "internal_id_cont",
      displayKey: "internal_id",
      success:    this.onRemoteTypeaheadSuccess.bind(this),
    });
  }

  onRemoteTypeaheadSuccess(entity, options) {
    window.location.pathname = `/trips/${entity.id}`;
    return true
  }
}
