/* eslint no-console:0 */

import Rails from 'rails-ujs';
Rails.start();

// Library Code
import 'code-prettify/src/prettify';                       // Code Prettify
import 'font-awesome/css/font-awesome.min.css'             // Font-Awesome
import 'popper.js/dist/popper.min.js';                     // Bootstrap
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker' // Bootstrap datepicker: for dates
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker'
import 'blueimp-file-upload/js/jquery.fileupload'          // Jquery-file-upload: direct-to-cloud-uploads
import 'blueimp-file-upload/css/jquery.fileupload'
import 'bootstrap-select/dist/js/bootstrap-select'         // Bootstrap select: multi-select for statuses
import 'bootstrap-select/dist/css/bootstrap-select'
import 'corejs-typeahead/dist/typeahead.jquery'            // Twitter Typeahead + Wrapper
import "../src/jquery/remote_typeahead";
// TODO: Prettify CSS?

// App Proper
import '../src/site';
