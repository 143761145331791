export default class Site {
  constructor() {
    this.initPopovers();
    // this.initCodePrettification();
    this.initTabHistorification();
    this.initDatePicker();
    this.initMultiSelect();
    this.initRemoteTypeahead();
  }

  initPopovers() {
    $("a[rel=popover]").popover();

    // Diff explanation popover
    $('a#diff_explanation').popover({
      trigger:   'hover',
      html:      true,
      placement: 'bottom',
      content: function() { 
        var selector = $(this).attr('data-content-css');
        return $(selector).html();
      },
    });
  }

  initCodePrettification() {
    // prettyPrint();
  }

  initTabHistorification() {
    // If landing on a page with a #! anchor, set the tab as active
    if (location.hash.substr(0, 2) == "#!") {
      $("a[href='#" + location.hash.substr(2) + "']").tab('show');
    }

    // When showing a tab, if it starts with a hash, change its history to be #!
    $("a[data-toggle='tab']").on("shown.bs.tab", function(e) {
      var hash = $(e.target).attr('href');

      if (hash.substr(0, 1) == '#') {
        location.replace("#!" + hash.substr(1));
      }
    });
  }

  initDatePicker() {
    $('input.datepicker').datepicker({
      format:         'yyyy-mm-dd',
      viewMode:       'years',
      autoclose:      true,
      todayBtn:       true,
      todayHighlight: true,
    });
  }

  initMultiSelect() {
    $('.selectpicker').selectpicker();
  }

  initRemoteTypeahead() {
    $("[data-toggle='remoteTypeahead']").remoteTypeahead();
  }
}
