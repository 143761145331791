export default class ModalForm {
  constructor({
    title,
    url,
    submitButtonText,
    modalDidLoad          = function() {},
    // Right now this is commented out because it needs to properly unbind
    // events, which we do in this class. If you need this functionality in the
    // future, be sure to modify modalDidHide at the end so that it can be
    // invoked by your subclass.
    // modalDidHide          = function() {},
    formSubmitDidClick    = function() {},
    formDidReceiveKeydown = function() {},
    formDidSubmit         = function() {},
    formDidSubmitSuccessfully = function() {},
    formDidNotSubmitSuccessfully = function() {},
    additionalHandlers    = [],
  }) {

    this.$modal                       = $('#modal');
    this.url                          = url;
    this.title                        = title;
    this.submitButtonText             = submitButtonText;
    this.modalDidLoad                 = modalDidLoad;
    // this.modalDidHide                 = modalDidHide;
    this.formSubmitDidClick           = formSubmitDidClick;
    this.formDidReceiveKeydown        = formDidReceiveKeydown;
    this.formDidSubmit                = formDidSubmit;
    this.formDidSubmitSuccessfully    = formDidSubmitSuccessfully;
    this.formDidNotSubmitSuccessfully = formDidNotSubmitSuccessfully;
    this.additionalHandlers           = additionalHandlers;

    this.bindEvents();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Control
  ////////////////////////////////////////////////////////////////////////////////
  show() {
    this.$modal.modal('show');
  }

  hide() {
    this.$modal.modal('hide');
  }


  ////////////////////////////////////////////////////////////////////////////////
  // Form Control
  ////////////////////////////////////////////////////////////////////////////////
  disableSubmit() {
    this.find('.submit').attr('disabled', true);
    this.find('#submit-spinner').removeClass('d-none');
  }

  enableSubmit() {
    this.find('.submit').attr('disabled', false);
    this.find('#submit-spinner').addClass('d-none');
  }

  submit() {
    const event = new Event('submit', { bubbles: true });
    this.find('form')[0].dispatchEvent(event);
  }

  reset() {
    this.find('form')[0].reset();
    this.enableSubmit();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Content API
  ////////////////////////////////////////////////////////////////////////////////
  setTitle(title) {
    this.find('.modal-header h5').html(title);
  }

  setSubmitButtonText(text) {
    this.find('.modal-footer .submit').html(text);
  }

  loadRemoteContent(url) {
    this.find('.modal-body')
      .load(url, this.modalDidLoad.bind(this));
  }

  setContent(content) {
    this.find('.modal-body').html(content);
  }


  focusOnElement(selector) {
    this.find(selector).focus();
  }

  find(selector) {
    return this.$modal.find(selector);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Events
  ////////////////////////////////////////////////////////////////////////////////
  modalShouldShow(event) {
    console.log("Called modalShouldShow.");
    this.setTitle(this.title);
    this.setSubmitButtonText(this.submitButtonText);
    this.loadRemoteContent(this.url);
  }

  modalDidHide(event) {
    console.log("Called modalDidHide.");
    this.unbindEvents();
  }

  bindEvents() {
    this.$modal.on('show.bs.modal',   this.modalShouldShow.bind(this));
    this.$modal.on('hidden.bs.modal', this.modalDidHide.bind(this));
    this.$modal.on('click',        '.submit', this.formSubmitDidClick.bind(this));
    this.$modal.on('keydown',      'form',    this.formDidReceiveKeydown.bind(this));
    this.$modal.on('submit',       'form',    this.formDidSubmit.bind(this));
    this.$modal.on('ajax:success', 'form',    this.formDidSubmitSuccessfully.bind(this));
    this.$modal.on('ajax:error',   'form',    this.formDidNotSubmitSuccessfully.bind(this));
    this.additionalHandlers.forEach((handler) => this.$modal.on(...handler));
  }

  unbindEvents() {
    this.$modal.off('click',        '.submit');
    this.$modal.off('keydown',      '.form');
    this.$modal.off('submit',       'form');
    this.$modal.off('ajax:success', 'form');
    this.$modal.off('ajax:error',   'form');
    this.$modal.off('show.bs.modal');
    this.$modal.off('hidden.bs.modal');

    this.additionalHandlers.forEach((handler) => this.$modal.off(...handler));
  }

}
