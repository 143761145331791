import TripEntryModalView from './trip_entry_modal_view';

export default class TripEntryFormView {
  constructor() {
    this.observeCreateClick();
    this.observeHoverEdit();
    this.observeDelete();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Observers
  ////////////////////////////////////////////////////////////////////////////////
  observeCreateClick() {
    var _this = this;

    $('a#new_trip_entry').click(function(e) {
      e.preventDefault();
      const url = $(e.currentTarget).attr('href');

      this.modalView = new TripEntryModalView({
        parentView: _this,
        url: url,
        title: "Add New Detail",
        submitButtonText: "Create Detail",
      });
    });
  }

  observeHoverEdit() {
    var _this = this;

    $(document).on('click', '#trip_entries > table td:not(.actions)', function(e) {
      const $tr      = $(e.currentTarget).parents('tr');
      const editable = $tr.data('editable');
      const url      = $tr.data('edit-url');

      if (editable) {
        this.modalView = new TripEntryModalView({
          parentView: _this,
          url: url,
          title: "Editing Detail",
          submitButtonText: "Update Detail",
        });
      }
    });
  }

  observeDelete() {
    var _this = this;

    $(document).on('click', '#trip_entries table td.actions a.destroy', function(e) {
      let actuallyDelete = confirm("Are you sure you want to delete this line? You can't undo this deletion -- it is permanent.")

      if (actuallyDelete) {
        var $this = $(e.currentTarget);

        $.ajax({
          url:      $this.attr('href'),
          data:     { _method: 'delete' },
          type:     'post',
          dataType: 'json',
          success:  function(data) {
            console.log("Completed with", data);
            _this.updateCompositeFields(data.composite_html);
            $this.parents('tr').remove();
          },
        });
      }

      return false;
    });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Management
  ////////////////////////////////////////////////////////////////////////////////
  formDidSubmitSuccessfully(status, data) {
    (status == 201) ?
      this.appendToTable(data.row_html) :
      this.replaceTableRow(data.row_id, data.row_html)

    this.updateCompositeFields(data.composite_html);
  }

  updateCompositeFields(html) {
    $('#trip #fiscal').html(html);
  }

  appendToTable(html) {
    $('#trip_entries > table').append(html);
  }

  replaceTableRow(selector, html) {
    $('#trip_entries > table').find(selector).replaceWith(html);
  }

};
