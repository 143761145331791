export default class TripAttachmentFormView {
  constructor() {
    this.fileUploadSelector = '#file-upload';

    this.initFileUpload();
    this.observeDelete();
    this.events = [];

  }

  initFileUpload = function() {
    var $uploader = $(this.fileUploadSelector);
    if ($uploader.length == 0) { return; }

    $uploader.fileupload({
      url:       $("meta[name='s3-upload-url']").attr('content'),
      paramName: 'file',
      type:      'POST',
      dropZone:  $(document),
      add:       this.onFileAdd.bind(this),
      progress:  this.onFileProgress.bind(this),
      fail:      this.onFail.bind(this),
      stop:      this.onStop.bind(this),
    });

    $(document).on('dragenter', function(e) { 
      $('#drop-zone-overlay').addClass('hovering')
        .delay(500)
        .addClass('darken');
    });

    $('#drop-zone-overlay').on('dragleave', function(e) { 
      $('#drop-zone-overlay').removeClass('darken')
        .removeClass('hovering');
    });

    $('#drop-zone-overlay').on('drop', function(e) {
      $(e.currentTarget).removeClass('darken')
        .removeClass('hovering');
    });
  }

  onFileAdd = function(event, data) {
    var $row = $('#trip_attachment_tmpl table tbody tr')
      .first()
      .clone();

    var actions = $row.find('.actions').remove();

    $row.find('.file_name').text(data.files[0].name);
    $row.find('.size').text('');

    // TODO: move markup to view
    $row
      .append($('<td class="actions"><i class="fa.fa-sync.fa-spin" /></td>'))
      .appendTo('#trip_attachments table tbody');

    data.context = { el: $row, actions: actions };

    $.ajax({
      url:      $('#file-upload').data('signing-url'),
      type:     'GET',
      dataType: 'json',
      success:  this.onSignedUrlSuccess.bind(this, data),
      error:    this.onSignedUrlFailure.bind(this, data),
      data: {
        trip_attachment: {
          file_name: data.files[0].name,
        },
      },
    });
  }

  onSignedUrlSuccess = function(data, response) {
    var actions = data.context.el.find('.actions');
    // TODO: move markup to view
    actions
      .empty()
      .append('<div class="progress progress-striped active"><div class="progress-bar" /></div>');

    data.formData = response;
    data.submit().done(this.onSuccess.bind(this, data));
  }

  onSignedUrlFailure = function(data, error) {
    row.find('.actions').html("<i class='fa fa-exclamation-circle'></i>");
  }

  onFileProgress = function(event, data) {
    var pct = parseInt(data.loaded / data.total * 100, 10);
    data.context.el.find('.progress-bar').css('width', pct + "%")
  }

  onFail = function(event, data) {
    return;
  }

  onStop = function(event, data) {
    return;
  }

  onSuccess = function(data, scope, success, response) {
    var key = $(response.responseXML).find('Key').text();

    $.ajax({
      url:     $(this.fileUploadSelector).data('save-url'),
      type:    'POST',
      success: this.onCreateSuccess.bind(this, data),
      error:   this.onCreateError.bind(this, data),
      data: {
        trip_attachment: {
          file_uid: key,
        },
      },
    });
  }

  onCreateSuccess = function(data, response) {
    data.context.el.replaceWith($(response));
  }

  onCreateError = function(data, error) {
    return;
  }

  observeDelete = function() {
    $(document).on('click', '#trip_attachments table td.actions a.destroy', function(e) {
      var conf = confirm("Are you sure you want to delete this line? You can't undo this deletion -- it is permanent.");

      if (conf) {
        var $this = $(e.currentTarget);

        $.ajax({
          url:      $this.attr('href'),
          data:     { _method: 'delete' },
          type:     'post',
          dataType: 'json',

          success: function(data) {
            console.log("Completed with", data);
            $this.parents('tr').remove()
          },
        });
      }

      return false;
    });
  }
}
