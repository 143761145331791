import ModalForm from '../../components/modal_form';

export default class TripEntryModalView {
  constructor({ parentView, url, title, submitButtonText }) {
    this.parentView = parentView;
    this.url        = url;

    this.modal = new ModalForm({
      title:                 title,
      submitButtonText:      submitButtonText,
      url:                   this.url,
      modalDidLoad:          this.modalDidLoad.bind(this),
      formSubmitDidClick:    this.formSubmitDidClick.bind(this),
      formDidReceiveKeydown: this.formDidReceiveKeydown.bind(this),
      formDidSubmit:         this.formDidSubmit.bind(this),
      formDidSubmitSuccessfully:    this.formDidSubmitSuccessfully.bind(this),
      formDidNotSubmitSuccessfully: this.formDidNotSubmitSuccessfully.bind(this),

      additionalHandlers: [
        ['change', '#trip_entry_expense_type_id', this.expenseTypeChanged.bind(this)],
      ]
    });

    this.modal.show();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Events
  ////////////////////////////////////////////////////////////////////////////////
  modalDidLoad() {
    this.modal.focusOnElement('#trip_entry_expense_type_id');
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Form Events
  ////////////////////////////////////////////////////////////////////////////////
  formSubmitDidClick() {
    console.log("Hit formSubmitDidClick.");
    this.modal.disableSubmit();
    this.modal.submit();

    return false;
  }

  formDidReceiveKeydown(event) {
    if (event.which == 13) { return this.formSubmitDidClick(); }
  }

  formDidSubmit(event) {
    console.log("Hit formDidSubmit.");
    const type     = this.getExpenseType();
    const category = this.getExpenseCategory();
    const cost     = this.getCost();

    if (type == 'Advance' && category != 'Cash Advance') {
      event.preventDefault();

      window.alert("Trip entries with 'Advance' type must also have the category set to 'Cash Advance'.");
      this.modal.enableSubmit();
      return false;
    }

    if (category == 'Fuel Credit' && cost >= 0) {
      event.preventDefault();

      window.alert("Fuel Credit entries must have a negative cost.");
      this.modal.enableSubmit();
      return false;
    }
  }

  formDidSubmitSuccessfully(event) {
    console.log("Hit formDidSubmitSuccessfully.");
    let data, _, xhr;
    [data, _, xhr] = event.originalEvent.detail;

    this.parentView.formDidSubmitSuccessfully(xhr.status, data);
    this.modal.reset();

    if (xhr.status == 201) {
      this.modal.setContent(data.form_html);
      this.modalDidLoad();
    }
    else {
      this.modal.hide();
    }
  }

  formDidNotSubmitSuccessfully(event) {
    const response = $.parseJSON(event.originalEvent.detail[2].responseText);
    this.modal.setContent(response.form_html);
    this.modal.enableSubmit();
  }

  expenseTypeChanged() {
    let $shouldReimburse = this.modal.find('#trip_entry_reimburse_to_driver');
    const type = this.getExpenseType();

    if (type == 'Revenue') {
      $shouldReimburse.prop("checked", false);
      $shouldReimburse.attr('disabled', '');
    }
    else {
      $shouldReimburse.removeAttr('disabled');
    }

    // All advances are cash advance, so pre-select this.
    if (type == 'Advance') {
      let $categorySelect = this.modal.find('#trip_entry_expense_category_id');
      const cashAdvanceId = $categorySelect
        .find('option:contains(Cash Advance)')
        .val();
      $categorySelect.val(cashAdvanceId);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Accessors
  ////////////////////////////////////////////////////////////////////////////////
  getExpenseType() {
    return this.modal
      .find('#trip_entry_expense_type_id option:selected')
      .text();
  }

  getExpenseCategory() {
    return this.modal
      .find('#trip_entry_expense_category_id option:selected')
      .text();
  }

  getCost() {
    return new Number(
      this.modal.find('#trip_entry_cost').val()
    );
  }

}
