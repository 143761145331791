import TripFormModalView from './trip_form_modal_view';

export default class TripFormView {
  constructor() {
    // this.$modal = $('#modal');
    this.events = [];

    this.displayErrorsForLookupFields();
    this.observeLookupFields();
    this.observeCompanyCreateClick();
    this.observeDriverCreateClick();
  }

  //
  // Because we're using lookup fields which utilise hidden ID fields, any
  // errors on the model actually occur on these fields and are invisible to
  // the user.
  //
  // What we do here is look at each ID field and add the error class to its
  // corresponding visible lookup field if it's in an error state.
  //
  displayErrorsForLookupFields() {
    const fields = ['broker', 'drive_away', 'pick_up_from', 'deliver_to', 'bill_to', 'driver'];

    for (var field of fields) {
      const $idField     = $(`div.trip_${field}_id`);
      const $lookupField = $(`div.trip_${field}_lookup`);
      const $lookupGroup = $lookupField.find('.input-group');

      if ($idField.hasClass('form-group-invalid')) {

        $lookupField.addClass('form-group-invalid');
        $lookupGroup.addClass('is-invalid')
          .attr('aria-invalid', true)
          .find('input')
          .addClass('is-invalid')
          .attr('aria-invalid', true);

        $idField.find('.invalid-feedback')
          .appendTo($lookupField);
      }
    }
  }

  //
  // Adds quick lookups for all companies and the driver.
  //
  observeLookupFields() {
    var fields = ['broker', 'drive_away', 'pick_up_from', 'deliver_to', 'bill_to'];

    for (var field of fields) {
      $(`#trip_${field}_lookup`).remoteTypeahead({
        predicate: 'name_cont',
        url: `/${field}s.json`,
        idSelector: `#trip_${field}_id`,
      });
    }

    $("#trip_driver_lookup").remoteTypeahead({
      predicate: 'full_name_cont',
      url: '/drivers.json',
      idSelector: "#trip_driver_id",
      displayKey: (val) => { return val.full_name },
      additionalParameters: () => { return {company_id_eq: ($('#trip_drive_away_id').val() || '')} }
    });
  }

  observeCompanyCreateClick() {
    $('form.trip').on('click', 'button.new_company', (event) => {
      console.log("New Company button was clicked!");
      const href = $(event.currentTarget).attr('href');

      this.modalView = new TripFormModalView({
        parentView: this,
        url: href,
        title: "Add New Company",
        submitButtonText: "Create Detail",
      });
    });
  }

  observeDriverCreateClick() {
    $('form.trip').on('click', 'button.new_driver', (event) => {
      console.log("New Driver button was clicked!");
      const href = $(event.currentTarget).attr('href');

      this.modalView = new TripFormModalView({
        parentView:       this,
        url:              href,
        title:            "Add New Driver",
        submitButtonText: "Create Driver",
      });
    });
  }
}
