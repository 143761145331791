import Site                   from './scripts/site';
import TripFormView           from '../../src/trips/scripts/trip_form_view';
import TripView               from '../../src/trips/scripts/trip_view';
import TripAttachmentFormView from '../../src/trip_attachments/scripts/trip_attachment_form_view'
import TripEntryFormView      from '../../src/trip_entries/scripts/trip_entry_form_view';
import TripQuickOpenView      from '../../src/trips/scripts/trip_quick_open_view';
import UserFormView           from '../../src/users/scripts/user_form_view';

import './styles/site.sass';

$(function() {
  window.jQuery = window.$ = jQuery
  window.site = new Site();

  if ($('form.trip').length)         { new TripFormView; }
  if ($('#trip').length)             { new TripView; }
  if ($('#trip_attachments').length) { new TripAttachmentFormView; }
  if ($('#trip_entries').length)     { new TripEntryFormView; }
  if ($('#trip_quickopen').length)   { new TripQuickOpenView; }
  if ($('.user_form').length)        { new UserFormView; }
});
