import TripFormModalView from '../../trips/scripts/trip_form_modal_view';

export default class UserFormView {
  constructor() {
    this.observeDriverLookup();
    this.observeDriverCreateClick();
    // this.observeLoaded();
    // this.observeClosing();

    // this.events = [];
  }

  observeDriverLookup() {
    $('#user_driver_lookup').remoteTypeahead({
      predicate:        'full_name_cont',
      url:              '/drivers.json',
      idSelector:       '#user_driver_id',
      displayKey:       (val) => { return val.full_name },
    });
  }

  observeDriverCreateClick() {
    $('form.user_form').on('click', 'button.new_driver', (event) => {
      console.log("New Driver button was clicked");
      const href = $(event.currentTarget).attr('href');

      this.modalView = new TripFormModalView({
        parentView:       this,
        url:              href,
        title:            "Add New Driver",
        submitButtonText: "Create Driver",
      });
    });
  }

  formDidSubmitSuccessfully(status, data) {
    if (status == 201) {
      const selector = '#user_driver_id';

      $('#user_driver_lookup').val(data.driver.to_select_option);
      $('#user_driver_id').val(data.driver.id);
    }
  }
}
