import ModalForm from '../../components/modal_form';

export default class TripFormModalView {
  constructor({ parentView, url, title, submitButtonText }) {
    this.parentView = parentView;
    this.url        = url;

    this.modal = new ModalForm({
      title:                        title,
      submitButtonText:             submitButtonText,
      url:                          this.url,
      formSubmitDidClick:           this.formSubmitDidClick.bind(this),
      formDidSubmitSuccessfully:    this.formDidSubmitSuccessfully.bind(this),
      formDidNotSubmitSuccessfully: this.formDidNotSubmitSuccessfully.bind(this),
    });

    this.modal.show();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Events
  ////////////////////////////////////////////////////////////////////////////////
  formSubmitDidClick() {
    this.modal.disableSubmit();
    this.modal.submit();
    return false;
  }

  formDidSubmitSuccessfully(event) {
    let [data, _, xhr] = event.originalEvent.detail;

    if ('formDidSubmitSuccessfully' in this.parentView) {
      this.parentView.formDidSubmitSuccessfully(xhr.status, data);
    }

    this.modal.reset()
    this.modal.setContent(data.form_html);
    if (xhr.status == 201) { this.modal.hide(); }
  }

  formDidNotSubmitSuccessfully(event) {
    const response = $.parseJSON(event.originalEvent.detail[2].responseText);
    this.modal.setContent(response.form_html)
    this.modal.enableSubmit();
  }
}
