import ModalForm from '../../components/modal_form';

export default class TripModalView {
  constructor({ parentView, url, title, submitButtonText }) {
    this.parentView = parentView;
    this.url        = url;

    this.modal = new ModalForm({
      title:              title,
      submitButtonText:   submitButtonText,
      url:                this.url,
      formDidSubmit:      this.formDidSubmit.bind(this),
      formSubmitDidClick: this.formSubmitDidClick.bind(this),
    });

    this.modal.show();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modal Events
  ////////////////////////////////////////////////////////////////////////////////
  formSubmitDidClick(event) {
    this.modal.disableSubmit();
    this.modal.submit();
  }

  formDidSubmit(event) {
    // Something funky is happening with this because it's not technically a
    // remote form: instead, we're using native submit to send the form for
    // server-side processing / re-rendering.
    //
    // In other circumstances, we want to use the proper event bubbling that
    // happens in ModalForm, but in these limited circumstances it doesn't work.
    // Hence we need to just submit without triggering an event.

    this.modal.find('form')[0].submit();
  }
}
