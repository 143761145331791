class Typeahead {
  constructor($typeahead, $idInput, url, predicate, displayKey, options) {
    this.$typeahead = $typeahead;
    this.$idInput   = $idInput;
    this.url        = url;
    this.predicate  = predicate;
    this.displayKey = displayKey;
    this.options    = options;

    this.createTypeahead();
  }

  onKeyUp() {
    this.$idInput.val('');
  }

  createTypeahead() {
    this.$typeahead.typeahead({ highlight: true, autoselect: true }, {
      source:     this.onTypeaheadRequest.bind(this),
      displayKey: this.displayKey,
    });

    this.$typeahead.on('typeahead:select', this.onTypeaheadSelect.bind(this));
    this.$typeahead.on('typeahead:open',   this.onTypeaheadOpen.bind(this));
  }

  onTypeaheadOpen() {
    this.$idInput.val('');
  }

  onTypeaheadSelect(event, entity, datasetName) {
    this.$idInput.val(entity.id);
    if (this.options.success) { this.options.success(entity, this.options); }
  }

  onTypeaheadRequest(query, _, onAsyncResult) {
    // Build a search query, i.e. :q => {:name_cont => #{query}}
    let search = {};
    search[this.predicate] = query;

    if (this.options.additionalParameters) {
      var extra = (typeof this.options.additionalParameters == 'function') ?
        this.options.additionalParameters() : this.options.additionalParameters
      search = jQuery.extend(search, extra);
    }

    $.get(this.url, { q: search }, onAsyncResult);
  }
}

$.fn.remoteTypeahead = function({idSelector = '', url = '', predicate = '', displayKey = '', ...options} = {}) {
  // Should something happen if we don't pass options here?
  this.each(function() {
    let $this = $(this);
    _idSelector = idSelector || $this.data('idselector');
    _url        = url        || $this.data('url');
    _predicate  = predicate  || $this.data('predicate');
    _displayKey = displayKey || $this.data('displaykey') || 'name';

    let typeahead = new Typeahead($this, $(_idSelector), _url, _predicate, _displayKey, options);
  });
};
