import TripModalView from './trip_modal_view';

export default class TripView {
  constructor() {
    this.observeChangeStatusClicked()

    const buttons = [
      ["#pick_up",  "Pick Up"],
      ["#deliver",  "Deliver"],
      ["#finalize", "Finalize"],
    ];


    buttons.forEach(
      (button) => {
        console.log("Binding on click for", button);
        this.observeButtonPress(...button);
      }
    );
  }

  observeChangeStatusClicked() {
    $('#trip').on('click', 'a#change_status', function(e) {
      var result = confirm("You are about to change the status of a trip. " +
                           "This change might be visible to the customer. " +
                           "This should only be done if a mistake has been " +
                           "made when setting the current status for this " +
                           "trip. Are you sure you want to change this status?");

      if (result) {
        $('#status_read').addClass('d-none');
        $('#status_write').removeClass('d-none');
      }

      e.preventDefault();
    });
  }

  observeButtonPress(selector, text) {
    $('#trip').on('click', selector, (e) => {
      e.preventDefault();
      console.log(`Clicked ${selector} button...`);

      const url       = $(e.currentTarget).attr('href');
      const title     = `Confirm ${text}`;
      const submitUrl = $(e.currentTarget).data('submit-url');

      this.modalView = new TripModalView({
        parentView:       this,
        url:              url,
        title:            title,
        submitButtonText: title,
        dataSubmitUrl:    submitUrl,
      });

    });
  }
}
